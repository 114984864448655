import styled from "styled-components";


const BannerSectionWrapper = styled.section`

    @media(min-width:768px){
        max-height:900px;
    }
    padding: 190px 0 120px 0;
    margin: 80px 0 0 0;
    background-size: 50%;
    background-position: center right 35px;
    p{
        font-size: 16px;
        font-weight: 300; 
        margin: 0 0 70px 0;
    }
    h4{
        margin-bottom: 8px;
        font-size: 18px;
        color: ${props => props.theme.secondaryColor};
        text-transform: uppercase;
    }
    .section-title{
        h1{
            font-weight: 300;
            font-size: 60px;
            line-height: 75px;
            margin: 0 0 38px 0; 
            span{
                font-weight: 600;
            }
        }
        
    }
    .banner-btn.one{
        background: #8A57DE;
        color: #fff;
        &:hover{
            background: #EEE6FA;
            color: #8A57DE;
        }
    }
    .banner-btn.two{
        margin-left:30px;
        background: #FFEBEC;
        color: #FB7B81;
        &:hover{
            background: #FB7B81;
            color: #fff;
        }
    }
    .banner-btn+.banner-btn{
        margin-left: 30px;
    }
    
    @media only screen and (max-width: 912px) {
        margin: 30px 0 0 0;
        background-size: 57%;
        padding: 120px 0 60px 0;
        .section-title{
            h1{
                font-size: 32px;
                line-height: 42px;
                margin: 0 0 25px 0;
            }
            h4{
                font-size: 16px;
            }
        }
        p{ 
            margin: 0 0 30px 0;
        }
        .banner-btn{
            min-width: 140px;
        }
        .banner-btn+.banner-btn{
            margin-left: 17px;
        }
     }
     @media only screen and (max-width: 760px) {
        padding: 130px 0 60px 0;
     }
     @media only screen and (max-width: 568px) {
        background: none;
        .banner-btn{
            min-width: 160px;
        }
     }
     @media only screen and (max-width: 480px) {
        padding: 120px 0 0 0;
        .section-title{
            h1{
                font-size: 35px;
                line-height: 50px;
            }
        }
         .vintage-box{
             &:before{
                 content: none;
             }
         }
     }
     @media only screen and (max-width: 380px) {
       
        .section-title{
            h1{
                font-size: 30px;
                line-height: 46px;
                margin: 0 0 30px 0;
            }
            h4{
                font-size: 15px;
            }
        }
        .banner-btn{
            font-size: 14px;
            min-width: 127px;
            padding: 14px 12px; 
        }
        .banner-btn+.banner-btn{
            margin-left: 15px;
        }
     }
     @media only screen and (max-width: 330px) {
        .section-title{
            h1{
                font-size: 23px;
                line-height: 35px;
            }
        }
     }

     .bottle-ghost-image-wrapper{
        @media(max-width:992px){
            display:none;
        } 
        animation: flyin .5s 3s ease forwards;
        transform: scale(0);
        opacity:0;         
        filter: blur(10px);

        .bottle-ghost-image-hover{        
            animation-name: hovering;
            animation-duration: 3s;
            animation-iteration-count: 15;
            animation-timing-function: ease-in-out;
        }
     }
     .phone-image-wrapper{   
        @media(max-width:992px){
            display:none;
        } 
        animation: goDown 2.5s 2s ease forwards;    
        transform: translate(20px,-680px);
        width: 250px;
        height: auto;
        position: relative;
        .sidebar{
            position:absolute;
            transform:translate(-50%,-50%)!important;
            top:50%;
            left:50%;
        }
    }
     @keyframes hovering {
        from {transform:translate(0, );}
        50% {transform:translate(0, -25px);}
        to {transform: translate(0, );}
    }

    @keyframes goDown {
        from {
            transform: translate(20px,-680px) rotate(0) skew(0, 0); 
        }
        50% {
            transform: translate(150px, -350px) rotate(80deg) skew(10deg, -20deg);
        }
        60% {
            transform: translate(150px, -350px) rotate(80deg) skew(10deg, -20deg);
        }
        to {
            transform: translate(150px, -350px) rotate(80deg) skew(10deg, -20deg);
            opacity:0;
        }
    }

    @keyframes flyin {
        from{            
            transform:translate(0, 200px);
        }
        50% { 
            opacity:.3;
            filter: blur(6px);
        }
        80% { 
            opacity:.6;
        }
        to { 
            transform:translate(0, 0);
            opacity:1;
            filter: blur(0);
            transform: scale(1);
        }
    }
     
`

export default BannerSectionWrapper;