import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Button from "../../components/UI/Button";
import { Col, Container, Row } from "../../components/UI/Grid";
import SectionTitle from "../../components/UI/SectionTitle";
import VintageBox from "../../components/UI/VintageBox";
import React from "react";
import { FaAddressCard, FaBlog } from "react-icons/fa";
import BannerSectionWrapper from "./banner.style";
import Particle from "../../components/UI/Particle/index";
import TypedFont from "../TypedFont";
import GaLink from "../UI/Ga-link";

const Banner = () => {




  const data = useStaticQuery(graphql`
  query {
    ghost:file(relativePath: {eq: "bottleghost/ghost-default.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    phone:file(relativePath: {eq: "bottleghost/phone.png"}) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)

  return (
    <BannerSectionWrapper>
      <Particle />
      <Container fullWidthSM>
        <Row>
          <Col sm={7} md={6} lg={7}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>
                App & Web Developer
              </h4>

              <h1>
                Wir erfüllen<span> gemeinsam </span>deine Wünsche
              </h1>

            </SectionTitle>
            <p>
            Mein Name lautet Philipp, freiberuflicher Full-Stack Entwickler mit Schwerpunkt App & Frontend Entwicklung. Mit Beginn meines 30. Lebensjahres habe ich mich dazu entschieden unter die Freiberufler zu gehen, um meine langjährigen Erfahrungen in der App & Webentwicklung als solcher anzubieten.
            </p>

            <VintageBox right={true} vintageOne={true}>

              <GaLink to="cv" site="home" value="cv">
                <Button className="banner-btn one">
                  <FaAddressCard className="icon-left" />
                  Zum CV
                </Button>
              </GaLink>


              <GaLink to="blog"  site="home" value="blog">
                <Button className="banner-btn two">
                  <FaBlog className="icon-left" /> Zum Blog
              </Button>
              </GaLink>
            </VintageBox>
          </Col>
          <Col sm={5} md={6} lg={5}>
            <div className="bottle-ghost-image-wrapper">
              <div className="bottle-ghost-image-hover">
                <Img alt="ghostbottle" fluid={data.ghost.childImageSharp.fluid}></Img>
              </div>
            </div>
            <div className="phone-image-wrapper">
              <Img alt="phonewrapper" style={{ overflow: 'visible' }} fluid={data.phone.childImageSharp.fluid}></Img>
              <TypedFont delay={true}>Hallo!</TypedFont>
            </div>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default Banner;
