import React from "react"
import Layout from "../components/UI/Layout/layout"
import SEO from "../components/seo"
import Banner from '../components/Banner/index'
import Integrations from "../components/Integrations/index"
import Contact from "../components/Contact/index"
import Fade from 'react-reveal/Fade';

const IndexPage = (props) => (
  <Layout>
    <SEO 
    metaTitle="Home" 
    metaDescription="Entwickeln wir tolle Apps zusammen - Dein Transparenter Partner für Hybride Apps mit React Native / Ionic und Web Applikationen mit React und Angular" />
    <Banner />
    <Fade>
      <Integrations />
    </Fade>
    <Fade>
      <Contact />
    </Fade>
  </Layout>
)

export default IndexPage
