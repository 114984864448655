import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

import { Container, Row, Col } from "../UI/Grid";
import SectionTitle from "../UI/SectionTitle";
import Button from "../UI/Button";
import VintageBox from "../UI/VintageBox";

import { FaMobile } from "react-icons/fa";
import { GoBrowser } from "react-icons/go";
import IntegrationItemWrapper from "./integrationSection.style";
import GaLink from "../UI/Ga-link";

const Integrations = () => {

  const data = useStaticQuery(graphql`
  query {
    angular:file(relativePath: {eq: "icons/angular.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    react:file(relativePath: {eq: "icons/react.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    nodejs:file(relativePath: {eq: "icons/nodejs.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ionic:file(relativePath: {eq: "icons/ionic.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gatsby:file(relativePath: {eq: "icons/gatsby.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    strapi:file(relativePath: {eq: "icons/strapi.png"}) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`)

  const integrationTechGrid = (
    <div className="integration__block__wrap">
      <div className="integration__block">
        <div className="integration__block__inner">
          <h3>
            <GaLink category="backlink" site="home" value="blog/category/app" to="blog/category/app">APP</GaLink>
          </h3>
          <GaLink category="backlink" site="home" value="blog/ionic" to="blog?tag=ionic">
            <Img alt="icon_ionic" width="100px" fixed={data.ionic.childImageSharp.fixed} alt="appion integration" />
          </GaLink>
          <GaLink category="backlink" site="home" value="blog/reactnative" to="blog?tag=reactnative">
            <Img alt="icon_react" fixed={data.react.childImageSharp.fixed} alt="appion integration" />
          </GaLink>

        </div>
        <div className="integration__block__inner">
          <h3>
            <GaLink category="backlink" site="home" value="blog/category/backend" to="blog/category/backend">Backend</GaLink>
          </h3>
          <GaLink category="backlink" site="home" value="blog/nodejs" to="blog?tag=nodejs">
            <Img alt="icon_node" fixed={data.nodejs.childImageSharp.fixed} alt="appion integration" />
          </GaLink>

        </div>
      </div>
      <div className="integration__block">
        <div className="integration__block__inner">
          <h3>
            <GaLink category="backlink" site="home" value="blog/category/frontend" to="blog/category/frontend">SPA</GaLink>
          </h3>

          <GaLink category="backlink" site="home" value="blog/angular" to="blog?tag=angular">
            <Img alt="icon_angular" width="100px" fixed={data.angular.childImageSharp.fixed} alt="appion integration" />
          </GaLink>


          <GaLink category="backlink" site="home" value="blog/react" to="blog?tag=react">
            <Img alt="icon_react" fixed={data.react.childImageSharp.fixed} alt="appion integration" />

          </GaLink>


        </div>
        <div className="integration__block__inner">
          <h3>
            <GaLink category="backlink" site="home" value="blog/category/jamstack" to="blog/category/jamstack">JAMstack</GaLink>
          </h3>
          <GaLink category="backlink" site="home" value="blog/gatsbyjs" to="blog?tag=gatsbyjs">
            <Img alt="icon_gatsby" fixed={data.gatsby.childImageSharp.fixed} alt="appion integration" />
          </GaLink>
          <GaLink category="backlink" site="home" value="blog/strapi" to="blog?tag=strapi">
            <Img alt="icon_strapi" fixed={data.strapi.childImageSharp.fixed} alt="appion integration" />
          </GaLink>
        </div>
      </div>
    </div>
  )

  const integrationTechText = (
    <>
      <SectionTitle
        className="section-title"
        leftAlign={true}
        UniWidth="100%"
      >
        <h4>Services</h4>

        <h2>
          <span>Meine</span> Technologien für <span>Dein</span> Vorhaben.
    </h2>
      </SectionTitle>
      <p className="integration-text">
        Was haben diese Technologien gemeinsam? Javascript pur! Ob App, Web oder Backend Entwicklung, wir sprechen hier eine Sprache! <br />Mehr dazu unter App & Web Entwicklung
  </p>

      <VintageBox right={true} vintageOne={true}>
        <GaLink to="app-entwicklung" site="home" value="app-entwicklung">
          <Button secondary className="banner-btn one">
            <FaMobile className="icon-left" />App
      </Button>
        </GaLink>

        <GaLink to="web-entwicklung" site="home" value="web-entwicklung">
          <Button secondary className="banner-btn two" >
            <GoBrowser className="icon-left" />Web
    </Button>
        </GaLink>
      </VintageBox>
    </>
  )

  return (
    <IntegrationItemWrapper>
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            {integrationTechGrid}
          </Col>
          <Col xs={12} sm={6} className="integration-content-wrap">
            {integrationTechText}
          </Col>
        </Row>
      </Container>
    </IntegrationItemWrapper>
  );
};

export default Integrations;
